import { Component, EventEmitter, Input, Output, ViewEncapsulation } from '@angular/core';

import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { Subscription } from 'rxjs';
import { ModalImagesModalComponent } from './modal-images-modal.component';

@Component({
  selector: 'app-modal-images',
  template: `
    <div class="modal-images" (click)="openModal()">
      <ng-content></ng-content>
    </div>
  `,
  encapsulation: ViewEncapsulation.None,
})
export class ModalImagesComponent {
  bsModalRef: BsModalRef;
  subscriptions: Subscription[] = [];

  @Input() images: string[];
  @Input() title: string;
  @Input() buttonText: string;
  @Output() modalClosed = new EventEmitter<string>();

  constructor(private modalService: BsModalService) {}

  openModal() {
    this.subscriptions.push(
      this.modalService.onHidden.subscribe(() => {
        this.modalClosed.emit('closed');
        this.unsubscribe();
      })
    );

    this.bsModalRef = this.modalService.show(ModalImagesModalComponent, {
      initialState: {
        modalRef: this.bsModalRef,
        images: this.images,
        title: this.title,
      } as any,
      class: 'modal-images__modal modal-dialog-centered',
    });
  }

  unsubscribe() {
    this.subscriptions.forEach((subscription: Subscription) => {
      subscription.unsubscribe();
    });
    this.subscriptions = [];
  }
}
