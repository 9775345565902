import { Component, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'app-step-columns',
  template: `
    <div class="row">
      <div class="col-md-6"><ng-content select="[left]"></ng-content></div>
      <div class="col-md-6"><ng-content select="[right]"></ng-content></div>
    </div>
  `,
  host: { class: 'container mb-4' },
  encapsulation: ViewEncapsulation.None,
})
export class StepColumnsComponent {}
