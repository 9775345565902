<ng-container *ngIf="templateSelected">
  <app-step-wrapper class="offer-preview-custom-em">
    <div class="container">
      <div class="offer-preview-custom-em__content-wrapper">
        <div class="mb-2">
          <h1 [appLocalizedText]="title$ | async"></h1>
          <p [appLocalizedText]="subtitle$ | async"></p>
        </div>
        <div class="mb-5">
          <div class="offer-preview-custom-em__template_options_wrapper" *ngIf="templateCount > 1">
            <div class="offer-preview-custom-em__template_options-note mb-4">
              <small>{{ 'EM.OFFERPREVIEW.TEMPLATES.SELECT_TEMPLATE' | translate }}</small>
            </div>
            <div class="offer-preview-custom-em__template_options_wrapper__template_list">
              <app-tab-button
                *ngFor="let template of templateListContent"
                [caption]="
                  getLocalizedItemFromObjectWrapper(template.tab)
                    | interpolateStringWithObjectValues: valueFormatted(template.id)
                "
                [active]="templateSelected === template.id"
                [icon]="getIcon(template)"
                (click)="templateSelect(template)">
              </app-tab-button>
            </div>
          </div>
        </div>
        <div class="offer-preview-custom-em__two-col-row-wrapper">
          <div class="offer-preview-custom-em__two-col-row-wrapper__col">
            <div class="offer-preview-custom-em__modal-images-wrapper">
              <app-modal-images
                [images]="templateSelectImages(templateSelected)"
                [title]="
                  templateSelectTitle(templateSelected)
                    | interpolateStringWithObjectValues: valueFormatted(templateSelected)
                ">
                <div class="offer-preview-custom-em__template-image">
                  <img src="{{ templateSelectImage(templateSelected) }}" />
                  <div class="offer-preview-custom-em__template-image-button">
                    <app-icon [name]="'looking-glass-plus'" [size]="24"></app-icon>
                  </div>
                </div>
              </app-modal-images>
            </div>
          </div>
          <div class="offer-preview-custom-em__two-col-row-wrapper__col">
            <h3>
              {{
                templateSelectTitle(templateSelected)
                  | interpolateStringWithObjectValues: valueFormatted(templateSelected)
              }}
            </h3>
            <h4>CHF {{ valueFormatted(templateSelected).totalCosts }}.–</h4>
            <div
              class="offer-preview-custom-em__template-description"
              [innerHTML]="
                templateSelectDescription(templateSelected)
                  | interpolateStringWithObjectValues: valueFormatted(templateSelected)
              "></div>
          </div>
        </div>

        <!-- BOTTOM-BOX -->
        <app-offer-preview-residential-bottom-box
          [hideTitle]="true"
          *ngIf="!isCommercial"></app-offer-preview-residential-bottom-box>
        <app-offer-preview-commercial-bottom-box
          *ngIf="isCommercial"></app-offer-preview-commercial-bottom-box>
        <!-- /BOTTOM-BOX -->
      </div>
    </div>
  </app-step-wrapper>
</ng-container>
