import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { APP_INITIALIZER, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { PorscheDesignSystemModule } from '@porsche-design-system/components-angular';
import { ScullyLibModule } from '@scullyio/ng-lib';
import { EmStateAction, EmStateEffect } from '@theia-cc/em/state';
import { ENVIRONMENT, ILocaleConfig, LOCALE_CONFIG } from '@theia-cc/shared/helpers';
import { HttpErrorInterceptor, LanguageService, ShareLinkService } from '@theia-cc/shared/services';
import { SharedStoreModule } from '@theia-cc/shared/store';
import { FormatNumberPipe } from '@theia-cc/shared/util-ui';
import { DeviceDetectorModule } from 'ngx-device-detector';
import { environment } from '../environments/environment';

import { CookieService } from 'ngx-cookie-service';
import { AppComponent } from './app.component';
import { ShareLinkServicePorsche } from './core/share-link.service';
import { ShareOfferPreviewResolver } from './core/share-offer-preview.resolver';
import { StoreModule } from './store/store.module';

export function initializeApp(languageService: LanguageService) {
  return () => languageService.setInitialLanguage();
}

const LOCALE_CONFIG_PORSCHE: ILocaleConfig = {
  LOCALES: ['de', 'fr', 'it', 'en'],
  LOCALE_STRINGS_FORMAL: {
    de: 'assets/locales/de-formal.json',
    fr: 'assets/locales/fr-formal.json',
    it: 'assets/locales/it-formal.json',
    en: 'assets/locales/en-US.json',
  },
};

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    HttpClientModule,
    RouterModule.forRoot(
      [
        {
          path: 'offerpreview',
          loadChildren: () =>
            import('./steps/offer-preview/offer-preview.module').then(m => m.OfferPreviewModule),
          resolve: {
            data: ShareOfferPreviewResolver,
          },
        },
        {
          path: 'contact',
          loadChildren: () => import('./steps/contact/contact.module').then(m => m.ContactModule),
          resolve: {
            data: ShareOfferPreviewResolver,
          },
        },
        { path: '', pathMatch: 'full', redirectTo: 'offerpreview' },
        { path: '**', pathMatch: 'full', redirectTo: 'offerpreview' },
      ],
      { scrollPositionRestoration: 'top' }
    ),
    SharedStoreModule,
    StoreModule,
    TranslateModule.forRoot(),
    DeviceDetectorModule.forRoot(),
    ScullyLibModule,
    PorscheDesignSystemModule,
  ],
  providers: [
    {
      provide: APP_INITIALIZER,
      useFactory: initializeApp,
      deps: [LanguageService],
      multi: true,
    },
    { provide: LOCALE_CONFIG, useValue: LOCALE_CONFIG_PORSCHE },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpErrorInterceptor,
      multi: true,
    },
    { provide: ENVIRONMENT, useValue: environment },
    { provide: ShareLinkService, useClass: ShareLinkServicePorsche },
    ShareOfferPreviewResolver,
    EmStateAction,
    EmStateEffect,
    CookieService,
    FormatNumberPipe,
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
