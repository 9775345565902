import { Component, ViewEncapsulation } from '@angular/core';
import { CheckTypeEnum, FINISH_EM_CONTACT_COMMERCIAL } from '@theia-cc/em/core';
import {
  EmStateAction,
  EmStateEffect,
  genericTranslationSelector,
  IAppStateEm,
} from '@theia-cc/em/state';
import { SharedStoreEffect } from '@theia-cc/shared/store';
import { select } from '@angular-redux/store';
import { Observable } from 'rxjs';
import { ILanguageSpecificStringWithType } from '@theia-cc/shared/models';

const translationProperty = 'offerPreview';
@Component({
  selector: 'app-offer-preview-commercial-bottom-box',
  templateUrl: './offer-preview-commercial-bottom-box.component.html',
  encapsulation: ViewEncapsulation.None,
  styles: [
    `
      .offer-preview-em .summary__list > div,
      .offer-preview-custom-em .summary__list > div {
        width: 33%;
      }
    `,
  ],
})
export class OfferPreviewCommercialBottomBoxComponent {
  constructor(
    public effect: SharedStoreEffect,
    private stepActions: EmStateAction,
    private action: EmStateEffect
  ) {}

  @select((store: IAppStateEm) =>
    genericTranslationSelector(store, translationProperty, 'offerPreviewSummaryTitle')
  )
  offerPreviewSummaryTitle$: Observable<ILanguageSpecificStringWithType>;

  @select((store: IAppStateEm) =>
    genericTranslationSelector(
      store,
      translationProperty,
      'offerPreviewSummaryTotalNumberOfParkingspaces'
    )
  )
  offerPreviewSummaryTotalNumberOfParkingspaces$: Observable<ILanguageSpecificStringWithType>;

  @select((store: IAppStateEm) =>
    genericTranslationSelector(
      store,
      translationProperty,
      'offerPreviewSummaryNumberOfParkingspacesPreparedToBeElectrified'
    )
  )
  offerPreviewSummaryNumberOfParkingspacesPreparedToBeElectrified$: Observable<ILanguageSpecificStringWithType>;

  @select((store: IAppStateEm) =>
    genericTranslationSelector(
      store,
      translationProperty,
      'offerPreviewSummaryNumberOfParkingspacesToBeEquippedCommercial'
    )
  )
  offerPreviewSummaryNumberOfParkingspacesToBeEquippedCommercial$: Observable<ILanguageSpecificStringWithType>;

  @select((store: IAppStateEm) =>
    genericTranslationSelector(
      store,
      translationProperty,
      'commercialBottomBoxRequestOnsiteCheckContent'
    )
  )
  commercialBottomBoxRequestOnsiteCheckContent$: Observable<ILanguageSpecificStringWithType>;

  @select((store: IAppStateEm) =>
    genericTranslationSelector(
      store,
      translationProperty,
      'commercialBottomBoxRequestOnsiteCheckButton'
    )
  )
  commercialBottomBoxRequestOnsiteCheckButton$: Observable<ILanguageSpecificStringWithType>;

  @select((store: IAppStateEm) =>
    genericTranslationSelector(store, translationProperty, 'commercialBottomBoxGoToContactContent')
  )
  commercialBottomBoxGoToContactContent$: Observable<ILanguageSpecificStringWithType>;

  @select((store: IAppStateEm) =>
    genericTranslationSelector(store, translationProperty, 'commercialBottomBoxGoToContactButton')
  )
  commercialBottomBoxGoToContactButton$: Observable<ILanguageSpecificStringWithType>;

  @select((store: IAppStateEm) => ({
    TotalNumberOfParkingspaces: store?.collectedData.totalNumberOfParkingspaces ?? 0,
    NumberOfParkingspacesPreparedToBeElectrified:
      store?.collectedData.numberOfParkingspacesPreparedToBeElectrified ?? 0,
    NumberOfParkingspacesToBeEquippedCommercial:
      store?.collectedData.numberOfParkingspacesToBeEquippedCommercial ?? 0,
  }))
  offerPreviewSummaryData$: Observable<Record<string, number>>;

  requestRemoteCheck(): void {
    this.stepActions.storeCheckType(CheckTypeEnum.OnSiteCheck);
    this.commercialBottomBoxGoToContact();
  }

  commercialBottomBoxGoToContact(): void {
    this.action.buttonActions(FINISH_EM_CONTACT_COMMERCIAL);
  }
}
