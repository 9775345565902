import { ICollectedDataEm } from '@theia-cc/em/state';
import { IConfigQueryParamsBase, ILanguageSpecific } from '@theia-cc/shared/models';
import { IAppStateBase, INITIAL_WIZARD } from '@theia-cc/shared/store';
import { PorscheStateAction } from './porsche-state.action';

export interface ICollectedDataPorsche extends ICollectedDataEm {
  dealerEmail?: string;
  centerInfo?: any;
}

export interface IAppStatePorsche extends IAppStateBase {
  config: {
    queryParams: IConfigQueryParamsBase;
    org: {
      name: string;
      logoLinkUrl?: ILanguageSpecific<string>;
    };
    envVariables: {
      appKey: string;
    };
    defaultLanguage: string;
  };
  collectedData: ICollectedDataPorsche;
}

export const INITIAL_STATE: IAppStatePorsche = {
  device: {
    device: null,
    browser: null,
  },
  wizard: { ...INITIAL_WIZARD, isSharedOfferPreview: true },
  config: {
    queryParams: {
      partnerId: undefined,
    },
    org: {
      name: 'Porsche',
      logoLinkUrl: {
        de: 'https://www.porsche.com/swiss/de/',
        fr: 'https://www.porsche.com/swiss/fr/',
        it: 'https://www.porsche.com/swiss/it/',
        en: 'https://www.porsche.com/swiss/de/',
      },
    },
    envVariables: {
      appKey: '495e0c7338e24c7584477cf1808b1196',
    },
    defaultLanguage: 'de',
  },
  currentLanguage: null,
  collectedData: {
    variantIdEm: null,
    parkingspace: null,
    poweroutlet: null,
    connectpv: null,
    nboFetchedEm: [],
    user: {
      companyName: null,
      email: null,
      emailDisclaimer: null,
      firstName: null,
      lastName: null,
      telephone: null,
      title: null,
    },
    userAddress: {
      zip: null,
      place: null,
      street: null,
      lat: null,
      lon: null,
      x: null,
      y: null,
      addressString: null,
      hasGeometry: null,
    },
    fuseBox: null,
    nboLeadIdEm: null,
    leadStatus: null,
    leadTag: null,
    leadTracers: null,
    leadComment: null,
    leadCallbackTime: {
      IsCallbackTimeMorning: null,
      IsCallbackTimeAfternoon: null,
      IsCallbackTimeEvening: null,
      IsCallbackTimeWeekend: null,
    },
    chargingPower: null,
    orderType: null,
    selectedTemplateEm: null,
    totalNumberOfParkingspaces: null,
    distanceFuseboxToChargingstation: null,
    selectedTemplateIdEm: null,
    whereToInstallCharger: null,
    numberOfParkingspacesToBeEquippedResidential: null,
    numberOfParkingspacesToBeEquippedCommercial: null,
    numberOfParkingspacesPreparedToBeElectrified: null,
    distanceFuseboxToFlatRibbonCable: null,
    numberOfRibbonCables: null,
    meterConnectionBetweenRibbonCable: null,
    parkingSpaceArrangement: null,
    checkType: null,
  },
};

export const collectedDataReducerPorsche = (
  state: ICollectedDataPorsche = INITIAL_STATE.collectedData,
  action: { type: any; payload: any }
): ICollectedDataPorsche => {
  const { payload, type } = action;

  switch (type) {
    case PorscheStateAction.STORE_DEALER_EMAIL:
      return {
        ...state,
        dealerEmail: payload,
      };

    case PorscheStateAction.STORE_CENTER_INFO:
      return {
        ...state,
        centerInfo: payload,
      };

    default:
      return state;
  }
};
