<div
  [ngClass]="{
    'offer-preview-dropdown': true,
    'offer-preview-dropdown--active': active
  }">
  <div class="offer-preview-dropdown__icon">
    <app-icon class="offer-preview-dropdown__icon-icon" [name]="icon" [size]="iconSize"></app-icon>
  </div>
  <div class="offer-preview-dropdown__caption">
    <div [innerHTML]="caption"></div>
    <div
      class="offer-preview-dropdown__tooltip"
      [tooltip]="tooltipContent"
      placement="top"
      container="body">
      <img src="assets/icons/var/icn-info.svg" />
    </div>
  </div>
  <div class="offer-preview-dropdown__select">
    <select class="custom-select" (change)="emit($event.target.value)">
      <option *ngFor="let item of items" [selected]="activeItem === item.id" [value]="item.id">
        {{ item.name | translate }}
      </option>
    </select>
  </div>
</div>
