import { dispatch } from '@angular-redux/store';
import { Injectable } from '@angular/core';

@Injectable()
export class PorscheStateAction {
  static readonly STORE_DEALER_EMAIL = 'STORE_DEALER_EMAIL';
  static readonly STORE_CENTER_INFO = 'STORE_CENTER_INFO';

  @dispatch()
  storeDealerEmail = (value: string) => ({
    type: PorscheStateAction.STORE_DEALER_EMAIL,
    payload: value,
  });

  @dispatch()
  storeCenterInfo = value => ({
    type: PorscheStateAction.STORE_CENTER_INFO,
    payload: value,
  });
}
