<div
  [ngClass]="{
    'choice-button-switch': true,
    'choice-button-switch--active': active
  }">
  <div class="choice-button-switch__icon" (click)="toggle()">
    <app-icon class="choice-button-switch__icon-icon" [name]="icon" [size]="72"></app-icon>
  </div>
  <div class="choice-button-switch__caption" (click)="toggle()">
    {{ caption }}
  </div>
  <div class="choice-button-switch__arrow">
    <ui-switch size="medium" [(ngModel)]="active" (change)="toggle()"></ui-switch>
  </div>
</div>
