<ng-container *ngIf="templateSelected">
  <ng-container *ngIf="!isDisableOfferEdit; else previewMain">
    <app-step-wrapper class="offer-preview-em">
      <div class="container">
        <div class="row">
          <div class="offer-preview-em__sidebar offer-preview-em__sidebar--desktop col-md-4">
            <div class="offer-preview-em__fake-bg"></div>
            <app-tab-button
              *ngFor="let template of templateListContent"
              [caption]="
                getLocalizedItemFromObjectWrapper(template.tab)
                  | interpolateStringWithObjectValues: valueFormatted(template.id)
              "
              [active]="templateSelected === template.id"
              [icon]="getIcon(template)"
              (click)="templateSelect(template)">
            </app-tab-button>
            <div class="offer-preview-em__sidebar-note" *ngIf="templateCount > 1">
              <small
                *ngIf="isCommercial"
                [appLocalizedText]="commercialSelectTemplate$ | async"></small>
              <small
                *ngIf="!isCommercial"
                [appLocalizedText]="residentialSelectTemplate$ | async"></small>
            </div>
          </div>

          <div class="offer-preview-em__topbar col-md-4">
            <swiper
              [config]="swiperConfig"
              (indexChange)="onSlideChange($event)"
              [(index)]="defaultSwiperIndex">
              <div *ngFor="let template of templateListContent">
                <app-tab-button
                  [caption]="
                    getLocalizedItemFromObjectWrapper(template.tab)
                      | interpolateStringWithObjectValues: valueFormatted(template.id)
                  "
                  [active]="templateSelected === template.id"
                  [icon]="getIcon(template)"
                  (click)="templateSelect(template)"></app-tab-button>
              </div>
            </swiper>
          </div>

          <ng-container *ngTemplateOutlet="previewMain"></ng-container>
        </div>
      </div>
    </app-step-wrapper>
  </ng-container>
</ng-container>

<ng-template #previewMain>
  <div class="offer-preview-em__main" [ngClass]="isDisableOfferEdit ? '' : 'col-md-8'">
    <div class="row offer-preview-em__template">
      <div class="col-sm-3">
        <app-modal-images
          [images]="templateSelectImages(templateSelected)"
          [title]="
            templateSelectTitle(templateSelected)
              | interpolateStringWithObjectValues: valueFormatted(templateSelected)
          ">
          <div class="offer-preview-em__template-image">
            <img src="{{ templateSelectImage(templateSelected) }}" />
            <div class="offer-preview-em__template-image-button">
              <app-icon [name]="'looking-glass-plus'" [size]="24"></app-icon>
            </div>
          </div>
        </app-modal-images>
      </div>
      <div
        class="col-sm-9 offer-preview-em__template-description"
        [innerHTML]="
          templateSelectTopBoxContent(templateSelected)
            | interpolateStringWithObjectValues: valueFormatted(templateSelected)
        "></div>
    </div>

    <!-- WIDGETBOX-->
    <div class="row widget-box-container">
      <!-- WIDGET COSTS-->
      <div class="col-md widget-box widget-box__cost">
        <div>
          <h3>{{ 'EM.OFFERPREVIEW.WIDGET_COSTS.TITLE' | translate }}</h3>
        </div>
        <div>
          <div class="widget-icons">
            <app-icon name="money" size="32"></app-icon>
            <div>CHF {{ valueFormatted(templateSelected).totalCosts }}.–</div>
          </div>
        </div>
      </div>
      <!-- WIDGET TIME-TO-FULL-CHARGE-->
      <div class="col-md widget-box widget-box__time-for-full-charge">
        <div>
          <h3>
            {{ 'EM.OFFERPREVIEW.WIDGET_TIMETOFULLCHARGE.TITLE' | translate }}
          </h3>
        </div>
        <div>
          <div class="widget-icons">
            <app-icon name="fullycharged" size="32"></app-icon>
            <div>{{ valueFormatted(templateSelected).timeToFullCharge }} h</div>
          </div>
        </div>
      </div>
      <!-- WIDGET CHARGING-POWER-->
      <div class="col-md widget-box widget-box__charging-power">
        <div>
          <h3>
            {{ 'EM.OFFERPREVIEW.WIDGET_CHARGING-POWER.TITLE' | translate }}
          </h3>
        </div>
        <div>
          <div class="widget-icons">
            <app-icon name="plug" size="32"></app-icon>
            <div>{{ valueFormatted(templateSelected).chargingPower }} kW</div>
          </div>
        </div>
      </div>
    </div>
    <!-- /WIDGETBOX-->

    <!-- PRICING TABLE RESIDENTIAL -->
    <ng-container *ngIf="!isCommercial">
      <div class="pricing-table row">
        <div class="col">
          <h3>{{ 'EM.OFFERPREVIEW.PRICING_TABLE.TITLE' | translate }}</h3>

          <!-- Underline -->
          <div class="pricing-table__section--underline"></div>

          <div class="pricing-table__section pricing-table__section--federal-grant">
            <div
              class="row justify-content-between"
              [innerHTML]="pricingTableChargingStationResidentialValue"></div>
            <br />
            <div
              class="row justify-content-between"
              [innerHTML]="
                'EM.OFFERPREVIEW.PRICING_TABLE.SYSTEM.CONTENT_2'
                  | translate: valueFormatted(templateSelected)
              "></div>
          </div>

          <!-- Underline -->
          <div class="pricing-table__section--underline"></div>

          <!-- Summary -->
          <div class="pricing-table__section pricing-table__section--total">
            <div
              class="row justify-content-between"
              [innerHTML]="
                'EM.OFFERPREVIEW.PRICING_TABLE.VAT' | translate: valueFormatted(templateSelected)
              "></div>
            <div
              [hidden]="isCarManufacturerSubsidyAmountZero"
              class="row justify-content-between"
              [innerHTML]="
                'EM.OFFERPREVIEW.PRICING_TABLE.CAR_MANUFACTURER_SUBSIDY'
                  | translate: valueFormatted(templateSelected)
              "></div>
            <div
              data-test="offer-preview-total-price"
              class="row justify-content-between"
              [innerHTML]="
                'EM.OFFERPREVIEW.PRICING_TABLE.TOTAL' | translate: valueFormatted(templateSelected)
              "></div>
          </div>
          <!-- /TOTAL -->
        </div>
      </div>
    </ng-container>
    <!-- /PRICING TABLE -->

    <!-- PRICING TABLE COMMERCIAL -->
    <ng-container *ngIf="isCommercial">
      <div class="pricing-table row">
        <div class="col">
          <h3>{{ 'EM.OFFERPREVIEW.PRICING_TABLE.TITLE' | translate }}</h3>

          <!-- Underline -->
          <div class="pricing-table__section--underline"></div>

          <div class="pricing-table__section pricing-table__section--federal-grant">
            <div
              class="row justify-content-between"
              [innerHTML]="
                removePricingInfosFromSystemContent(
                  this.templateSelectedObject.pricingTableChargingStation ||
                    'EM.OFFERPREVIEW.PRICING_TABLE.SYSTEM.CONTENT',
                  templateSelected,
                  false
                )
              "></div>
            <br />
            <div
              class="row justify-content-between"
              [innerHTML]="
                removePricingInfosFromSystemContent(
                  'EM.OFFERPREVIEW.PRICING_TABLE.SYSTEM.CONTENT_2',
                  templateSelected
                )
              "></div>
          </div>

          <!-- Underline -->
          <div class="pricing-table__section--underline"></div>

          <!-- Summary -->
          <div class="pricing-table__section pricing-table__section--total">
            <div
              [hidden]="isCarManufacturerSubsidyAmountZero"
              class="row justify-content-between"
              [innerHTML]="
                'EM.OFFERPREVIEW.PRICING_TABLE.CAR_MANUFACTURER_SUBSIDY'
                  | translate: valueFormatted(templateSelected)
              "></div>
            <div
              data-test="offer-preview-total-price"
              class="row justify-content-between"
              [innerHTML]="
                'EM.OFFERPREVIEW.PRICING_TABLE.TOTAL_COMMERCIAL'
                  | translate: valueFormatted(templateSelected)
              "></div>
          </div>
          <!-- /TOTAL -->
        </div>
      </div>
    </ng-container>
    <!-- /PRICING TABLE -->

    <!-- OPTIONS -->
    <div class="options" *ngIf="!isDisableOfferEdit">
      <app-offer-preview-dropdown
        *ngIf="templateSelectedObject.supportsMultipleChargingPowers && !(isFixPrice$ | async)"
        [caption]="'EM.OFFERPREVIEW.OPTIONS.CHARGING_POWER' | translate"
        [tooltipContent]="'EM.OFFERPREVIEW.OPTIONS.CHARGING_POWER_TOOLTIP' | translate"
        [icon]="'plug'"
        [iconSize]="32"
        (valueChange)="setChargingPower($event)"
        [items]="chargingPowerTypeItems"
        [activeItem]="templateSelectedObject.chargingPower"></app-offer-preview-dropdown>
    </div>
    <!-- /OPTIONS -->

    <!-- BOTTOM-BOX -->
    <app-offer-preview-residential-bottom-box
      *ngIf="!isCommercial"></app-offer-preview-residential-bottom-box>
    <app-offer-preview-commercial-bottom-box
      *ngIf="isCommercial"></app-offer-preview-commercial-bottom-box>
    <!-- /BOTTOM-BOX -->

    <!-- INFO BOX -->
    <div class="offer-preview-em__info-box">
      <div
        *ngIf="(templateSelectedObject?.data)[0]?.ChargingPlugAc === 'Type1'"
        [innerHTML]="'EM.OFFERPREVIEW.INFO_BOX.CHARGING_PLUG_TYPE1' | translate"></div>
      <div
        *ngIf="
          chargingPowerTypeNumbers[(collectedData$ | async).chargingPower] >
            (templateSelectedObject?.data)[0]?.MaxChargingPowerAc &&
          !(isChargingPowerEqualsThreePointSeven$ | async)
        ">
        {{
          ((templateSelectedObject?.data)[0]?.MaxChargingPowerAc === 7.2
            ? 'EM.OFFERPREVIEW.INFO_BOX.CHARGING_POWER_OF_THE_CAR_SMALLER_THAN_THE_STATION_AND_EQUALS_SEVEN_POINT_TWO'
            : 'EM.OFFERPREVIEW.INFO_BOX.CHARGING_POWER_OF_THE_CAR_SMALLER_THAN_THE_STATION'
          ) | translate: valueFormatted(templateSelected)
        }}
      </div>
      <div *ngIf="isChargingPowerEqualsThreePointSeven$ | async">
        {{
          'EM.OFFERPREVIEW.INFO_BOX.CHARGING_POWER_THREE_POINT_SEVEN'
            | translate: valueFormatted(templateSelected)
        }}
      </div>
    </div>
    <!-- /INFO BOX -->

    <!-- FLAT RATE INFO BOX -->
    <div class="row" *ngIf="fixPriceInfoBox$ | async as fixPriceInfoBox">
      <div
        class="col offer-preview-em__flat-rate-info-box"
        [innerHTML]="fixPriceInfoBox | translate"></div>
    </div>
    <!-- /FLAT RATE INFO BOX -->
  </div>
</ng-template>
