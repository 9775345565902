import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { SharedUtilUiModule } from '@theia-cc/shared/util-ui';
import { ModalModule } from 'ngx-bootstrap/modal';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { SwiperModule } from 'ngx-swiper-wrapper';
import { UiSwitchModule } from 'ngx-toggle-switch';
import { ModalImagesModalComponent } from './modal-images/modal-images-modal.component';
import { ModalImagesComponent } from './modal-images/modal-images.component';
import { OfferPreviewSwitchComponent } from './offer-preview-switch/offer-preview-switch.component';
import { TabButtonComponent } from './tab-button/tab-button.component';

@NgModule({
  declarations: [
    ModalImagesComponent,
    ModalImagesModalComponent,
    OfferPreviewSwitchComponent,
    TabButtonComponent,
  ],
  imports: [
    CommonModule,
    ModalModule.forRoot(),
    SharedUtilUiModule,
    SwiperModule,
    TooltipModule,
    UiSwitchModule,
    TranslateModule,
  ],
  exports: [
    ModalImagesComponent,
    ModalImagesModalComponent,
    OfferPreviewSwitchComponent,
    TabButtonComponent,
    SwiperModule,
    UiSwitchModule,
    TranslateModule,
  ],
})
export class SharedOfferPreviewUiModule {}
