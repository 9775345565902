<div class="bottom-box">
  <div class="row">
    <div class="col">
      <h3 [appLocalizedText]="offerPreviewSummaryTitle$ | async"></h3>
    </div>
  </div>
  <div class="row">
    <div class="col-sm-12">
      <div class="summary__list">
        <div
          [appLocalizedText]="offerPreviewSummaryTotalNumberOfParkingspaces$ | async"
          [appLocalizedInterpolateData]="offerPreviewSummaryData$ | async"></div>
        <div
          [appLocalizedText]="
            offerPreviewSummaryNumberOfParkingspacesPreparedToBeElectrified$ | async
          "
          [appLocalizedInterpolateData]="offerPreviewSummaryData$ | async"></div>
        <div
          [appLocalizedText]="
            offerPreviewSummaryNumberOfParkingspacesToBeEquippedCommercial$ | async
          "
          [appLocalizedInterpolateData]="offerPreviewSummaryData$ | async"></div>
      </div>
    </div>
  </div>
  <div class="row onsite-check mb-4">
    <div
      class="col-sm-7 mb-3 mb-sm-0"
      [appLocalizedText]="commercialBottomBoxGoToContactContent$ | async"></div>
    <div class="col-sm-5 d-flex">
      <app-button
        shadow="true"
        facet="secondary"
        block="true"
        (buttonClicked)="commercialBottomBoxGoToContact()"
        class="align-self-center flex-grow-1">
        <span [appLocalizedText]="commercialBottomBoxGoToContactButton$ | async"></span>
      </app-button>
    </div>
  </div>
  <div class="row remote-check mb-4">
    <div
      class="col-sm-7 mb-3 mb-sm-0"
      [appLocalizedText]="commercialBottomBoxRequestOnsiteCheckContent$ | async"></div>
    <div class="col-sm-5 d-flex">
      <app-button
        shadow="true"
        facet="secondary"
        block="true"
        (buttonClicked)="requestRemoteCheck()"
        class="align-self-center flex-grow-1">
        <span [appLocalizedText]="commercialBottomBoxRequestOnsiteCheckButton$ | async"></span>
      </app-button>
    </div>
  </div>
</div>
