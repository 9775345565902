<button
  type="button"
  [ngClass]="{
    'btn btn-outline-primary': true,
    'choice-button-person': true,
    'choice-button-person--active': active
  }">
  <div class="choice-button-person__icon">
    <app-icon
      class="choice-button-person__icon-icon"
      [name]="'person'"
      [size]="32"
      *ngFor="let person of personsArray"></app-icon>
  </div>
  <div class="choice-button-person__caption">{{ caption }}</div>
  <div class="choice-button-person__arrow">
    <app-icon
      class="choice-button-person__arrow-icon"
      [name]="choiceButtonChevronRightIconName"
      [size]="32"></app-icon>
  </div>
</button>
