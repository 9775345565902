import { NgRedux } from '@angular-redux/store';
import { Component, Input, ViewEncapsulation } from '@angular/core';
import { IAppStateBase } from '@theia-cc/shared/store';

export interface IChoiceButton {
  value: any;
  caption: string;
  type?: string;
  icon?: string | boolean;
}

@Component({
  selector: 'app-choice-button',
  templateUrl: './choice-button.component.html',
  encapsulation: ViewEncapsulation.None,
})
export class ChoiceButtonComponent {
  constructor(private store: NgRedux<IAppStateBase>) {
    const { choiceButtonChevronRightIconName } = this.store.getState().config.envVariables;
    if (choiceButtonChevronRightIconName) {
      this.choiceButtonChevronRightIconName = choiceButtonChevronRightIconName;
    }
  }

  @Input() icon: any = null;
  @Input() caption: string;
  @Input() captionHtml: string;
  @Input() active = false;

  @Input() width = 4;
  @Input() height = 4;

  public choiceButtonChevronRightIconName = 'chevron-right';

  isImageFile() {
    // if undefined, null return true to render empty rectangle
    if (!this.icon) {
      return true;
    }
    return (
      this.icon.includes('jpeg') ||
      this.icon.includes('jpg') ||
      this.icon.includes('png') ||
      this.icon.includes('gif')
    );
  }
}
