import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { SharedOfferPreviewUiModule } from '@theia-cc/shared/offer-preview-ui';
import { SharedStepsUiModule } from '@theia-cc/shared/step-ui';
import { InterpolateStringWithObjectValuesPipe } from 'libs/shared/util-ui/src/lib/interpolate-string-with-object-values.pipe';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { SwiperModule } from 'ngx-swiper-wrapper';
import { OfferPreviewCustomComponent } from './offer-preview-custom/offer-preview-custom.component';
import { OfferPreviewDefaultComponent } from './offer-preview-default/offer-preview-default.component';
import { OfferPreviewComponent } from './offer-preview.component';
import { SpecialEmOfferPreviewComponentsModule } from './special-em-offer-preview-components.module';

@NgModule({
  declarations: [OfferPreviewComponent, OfferPreviewDefaultComponent, OfferPreviewCustomComponent],
  imports: [
    CommonModule,
    RouterModule.forChild([
      {
        path: '',
        component: OfferPreviewComponent,
      },
    ]),
    SharedOfferPreviewUiModule,
    SharedStepsUiModule,
    SpecialEmOfferPreviewComponentsModule,
    SwiperModule,
    TooltipModule.forRoot(),
  ],
  providers: [InterpolateStringWithObjectValuesPipe],
  exports: [OfferPreviewComponent],
})
export class OfferPreviewModule {}
