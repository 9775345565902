import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { SharedUtilUiModule } from '@theia-cc/shared/util-ui';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { OfferPreviewDropdownComponent } from './offer-preview-dropdown/offer-preview-dropdown.component';
import { OfferPreviewResidentialBottomBoxComponent } from './offer-preview-residential-bottom-box/offer-preview-residential-bottom-box.component';
import { OfferPreviewCommercialBottomBoxComponent } from './offer-preview-commercial-bottom-box/offer-preview-commercial-bottom-box.component';

@NgModule({
  declarations: [
    OfferPreviewDropdownComponent,
    OfferPreviewResidentialBottomBoxComponent,
    OfferPreviewCommercialBottomBoxComponent,
  ],
  imports: [CommonModule, SharedUtilUiModule, TooltipModule, TranslateModule],
  exports: [
    OfferPreviewDropdownComponent,
    OfferPreviewResidentialBottomBoxComponent,
    OfferPreviewCommercialBottomBoxComponent,
  ],
})
export class SpecialEmOfferPreviewComponentsModule {}
