import { Component, Input, ViewEncapsulation } from '@angular/core';
import { CheckTypeEnum } from '@theia-cc/em/core';
import { EmStateAction, genericTranslationSelector, IAppStateEm } from '@theia-cc/em/state';
import { SharedStoreEffect } from '@theia-cc/shared/store';
import { select } from '@angular-redux/store';
import { Observable } from 'rxjs';
import { ILanguageSpecificStringWithType } from '@theia-cc/shared/models';

const translationProperty = 'offerPreview';
@Component({
  selector: 'app-offer-preview-residential-bottom-box',
  templateUrl: './offer-preview-residential-bottom-box.component.html',
  encapsulation: ViewEncapsulation.None,
  styles: [
    `
      p {
        font-weight: 400;
      }
    `,
  ],
})
export class OfferPreviewResidentialBottomBoxComponent {
  @Input() hideTitle = false;
  constructor(private effect: SharedStoreEffect, private stepActions: EmStateAction) {}

  @select((store: IAppStateEm) =>
    genericTranslationSelector(store, translationProperty, 'residentialBottomBoxTitle')
  )
  residentialBottomBoxTitle$: Observable<ILanguageSpecificStringWithType>;

  @select((store: IAppStateEm) =>
    genericTranslationSelector(
      store,
      translationProperty,
      'residentialBottomBoxRequestRemoteCheckButton'
    )
  )
  residentialBottomBoxRequestRemoteCheckButton$: Observable<ILanguageSpecificStringWithType>;

  @select((store: IAppStateEm) =>
    genericTranslationSelector(
      store,
      translationProperty,
      'residentialBottomBoxRequestRemoteCheckContent'
    )
  )
  residentialBottomBoxRequestRemoteCheckContent$: Observable<ILanguageSpecificStringWithType>;

  @select((store: IAppStateEm) =>
    genericTranslationSelector(
      store,
      translationProperty,
      'residentialBottomBoxRequestOnsiteCheckButton'
    )
  )
  residentialBottomBoxRequestOnsiteCheckButton$: Observable<ILanguageSpecificStringWithType>;

  @select((store: IAppStateEm) =>
    genericTranslationSelector(
      store,
      translationProperty,
      'residentialBottomBoxRequestOnsiteCheckContent'
    )
  )
  residentialBottomBoxRequestOnsiteCheckContent$: Observable<ILanguageSpecificStringWithType>;

  requestRemoteCheck(): void {
    this.setCheckTypeAndNavigateNext(CheckTypeEnum.RemoteCheck);
  }

  requestOnSiteCheck() {
    this.setCheckTypeAndNavigateNext(CheckTypeEnum.OnSiteCheck);
  }

  setCheckTypeAndNavigateNext(checkType: CheckTypeEnum) {
    this.stepActions.storeCheckType(checkType);
    this.effect.navigateNext();
  }
}
