import { select } from '@angular-redux/store';
import { Component, ViewEncapsulation } from '@angular/core';
import { enableCustomOfferPreview } from '@theia-cc/em/state';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-offer-preview-em',
  templateUrl: './offer-preview.component.html',
  encapsulation: ViewEncapsulation.None,
})
export class OfferPreviewComponent {
  @select(enableCustomOfferPreview)
  public $enableCustomOfferPreview: Observable<string>;
}
