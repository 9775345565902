import { NgRedux } from '@angular-redux/store';
import { Component, Input, ViewEncapsulation } from '@angular/core';
import { IAppStateBase } from '@theia-cc/shared/store';

@Component({
  selector: 'app-choice-button-person',
  templateUrl: './choice-button-person.component.html',
  encapsulation: ViewEncapsulation.None,
})
export class ChoiceButtonPersonComponent {
  @Input() caption: string;
  @Input() active = false;

  personsArray: number[];

  _persons: number;
  get persons(): number {
    return this._persons;
  }

  @Input()
  set persons(value: number) {
    this._persons = value;
    this.personsArray = Array.from(Array(this.persons)).map((x, i) => i);
  }

  public choiceButtonChevronRightIconName = 'chevron-right';

  constructor(private store: NgRedux<IAppStateBase>) {
    const { choiceButtonChevronRightIconName } = this.store.getState().config.envVariables;
    if (choiceButtonChevronRightIconName) {
      this.choiceButtonChevronRightIconName = choiceButtonChevronRightIconName;
    }
  }
}
