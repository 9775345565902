import { Component, Input } from '@angular/core';

import { BsModalRef } from 'ngx-bootstrap/modal';

@Component({
  selector: 'app-modal-content',
  template: `
    <div class="modal-header">
      <div>{{ title }}</div>
      <button
        type="button"
        class="close pull-right"
        aria-label="Cancel"
        (click)="bsModalRef.hide()">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body modal-images__modal-body">
      <swiper [config]="swiperConfig">
        <div *ngFor="let image of images"><img src="{{ image }}" /></div>
      </swiper>
    </div>
  `,
})
export class ModalImagesModalComponent {
  public swiperConfig = {
    navigation: true,
    pagination: true,
  };

  @Input() public images: string[] = [];

  @Input() public title = '';

  constructor(public bsModalRef: BsModalRef) {}
}
