import { NboEmobilityRequest } from '@api-cc';
import { CmsEnumsEm, ITemplateEm, ITemplateEmWithoutCms } from '@theia-cc/em/core';
import { ICollectedDataEm } from '@theia-cc/em/state';
import getSpecificEnumNameAndValues from './enum-resolver.utils';

export function filterTemplates(
  templateNames: string[],
  templates: ITemplateEm[],
  collectedData: ICollectedDataEm
): ITemplateEm[] {
  const { orderType } = collectedData;

  let filteredTemplates: ITemplateEm[] = [...templates];

  if (orderType !== null) {
    filteredTemplates = filteredTemplates.filter(template => {
      // Check if OrderTypeEnum is defined on the template in the cms - if so, continue filtering
      const chargingPowerEnums = getSpecificEnumNameAndValues(
        template.enums,
        CmsEnumsEm.OrderTypeEnum
      );

      if (chargingPowerEnums.length === 0) {
        return true;
      }

      const chargingPowerEnumValues = chargingPowerEnums.map(({ selectedValue }) => selectedValue);

      return ((chargingPowerEnumValues || []) as NboEmobilityRequest.OrderTypeEnum[]).some(
        x => x === orderType
      );
    });
  }

  filteredTemplates = filteredTemplates.filter(template => templateNames.includes(template.id));

  return filteredTemplates;
}

/* 
  This version of the filtering method is needed because the independent em-ccs do not use the cms right now and therefore are using the old hardcoded configuration for the templates
*/
export function filterTemplatesWithoutCms(
  templateNames: string[],
  templates: ITemplateEmWithoutCms[],
  collectedData: ICollectedDataEm
): ITemplateEmWithoutCms[] {
  const { orderType } = collectedData;
  let filteredTemplates: ITemplateEmWithoutCms[] = [...templates];
  if (orderType !== null) {
    filteredTemplates = filteredTemplates.filter(template =>
      ((template.data.supportedOrderTypes || []) as NboEmobilityRequest.OrderTypeEnum[]).some(
        x => x === orderType
      )
    );
  }
  filteredTemplates = filteredTemplates.filter(template => templateNames.includes(template.id));

  return filteredTemplates;
}
