<div class="bottom-box">
  <div class="row" *ngIf="!hideTitle">
    <div class="col" [appLocalizedText]="residentialBottomBoxTitle$ | async"></div>
  </div>
  <div class="row remote-check mb-4">
    <div
      class="col-sm-7 mb-3 mb-sm-0"
      [appLocalizedText]="residentialBottomBoxRequestRemoteCheckContent$ | async"></div>
    <div class="col-sm-5 d-flex">
      <app-button
        shadow="true"
        facet="secondary"
        block="true"
        (buttonClicked)="requestRemoteCheck()"
        class="align-self-center flex-grow-1">
        <span [appLocalizedText]="residentialBottomBoxRequestRemoteCheckButton$ | async"></span>
      </app-button>
    </div>
  </div>
  <div class="row onsite-check mb-4" *ngIf="residentialBottomBoxRequestOnsiteCheckContent$ | async">
    <div
      class="col-sm-7 mb-3 mb-sm-0"
      [appLocalizedText]="residentialBottomBoxRequestOnsiteCheckContent$ | async"></div>
    <div class="col-sm-5 d-flex">
      <app-button
        shadow="true"
        facet="secondary"
        block="true"
        (buttonClicked)="requestOnSiteCheck()"
        class="align-self-center flex-grow-1">
        <span [appLocalizedText]="residentialBottomBoxRequestOnsiteCheckButton$ | async"></span>
      </app-button>
    </div>
  </div>
</div>
