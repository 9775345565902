import { select } from '@angular-redux/store';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router, RoutesRecognized } from '@angular/router';
import { ConfigAction } from '@theia-cc/shared/store';
import { Subscription } from 'rxjs';
import { filter } from 'rxjs/operators';
import packageJson from '../../../../package.json';
import { IAppStatePorsche } from './store/state';

@Component({
  selector: 'theia-cc-root',
  template: `
    <router-outlet></router-outlet>
    <div class="spinner-wrapper" *ngIf="showLoadingSpinner$ | async">
      <p-spinner size="large" aria-label="Loading"> </p-spinner>
    </div>
  `,
})
export class AppComponent implements OnInit, OnDestroy {
  private subscription: Subscription = new Subscription();
  @select((state: IAppStatePorsche) => state.wizard.showLoadingSpinner)
  showLoadingSpinner$;
  constructor(
    private configAction: ConfigAction,
    private router: Router,
    private activatedRoute: ActivatedRoute
  ) {}

  public ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  public ngOnInit(): void {
    this.subscription.add(
      this.router.events
        .pipe(filter(event => event instanceof RoutesRecognized))
        .subscribe((event: RoutesRecognized) => {
          this.configAction.parseQParams(event.state.root.queryParams);
        })
    );

    console.log(
      '%c%s',
      'color: #03518c; font-size: 14px;',
      `${packageJson.name}-porsche version: ${packageJson.version}`
    );
  }
}
