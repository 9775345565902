import { Component, EventEmitter, Input, OnInit, Output, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'app-offer-preview-dropdown',
  templateUrl: './offer-preview-dropdown.component.html',
  encapsulation: ViewEncapsulation.None,
})
export class OfferPreviewDropdownComponent implements OnInit {
  @Input() caption: string;

  @Input() tooltipContent: string;

  @Input() icon: string;

  @Input() iconSize: number;

  @Input() active: boolean = false;

  @Input() activeItem;

  @Input() items: any[];

  @Output()
  valueChange: EventEmitter<any> = new EventEmitter<boolean>();

  ngOnInit(): void {}

  emit(event) {
    this.valueChange.emit(event);
  }
}
