import { Component, EventEmitter, Input, Output, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'app-tab-button',
  template: `
    <div
      [ngClass]="{
        'tab-button': true,
        'tab-button--active': active
      }">
      <div class="tab-button__icon-wrapper">
        <div class="tab-button__icon" [ngClass]="tabIconClass">
          <app-icon
            *ngIf="!isImageFile()"
            class="tab-button__icon-icon"
            [name]="icon"
            [size]="72"></app-icon>
          <div
            *ngIf="isImageFile()"
            class="tab-button__icon-image"
            [ngStyle]="{ 'background-image': 'url(' + icon + ')' }">
            <ng-container *ngIf="icon === null">?</ng-container>
          </div>
        </div>
      </div>
      <div class="tab-button__caption">
        <div [innerHTML]="caption"></div>
      </div>
    </div>
  `,
  encapsulation: ViewEncapsulation.None,
})
export class TabButtonComponent {
  @Input() tabIconClass: string;
  @Input() caption: string;
  @Input() icon: string;
  @Input() active = false;
  @Output() valueChange = new EventEmitter<boolean>();

  isImageFile() {
    // if undefined, null return true to render empty rectangle
    if (!this.icon) {
      return true;
    }
    return this.icon.includes('jpg') || this.icon.includes('png') || this.icon.includes('gif');
  }

  emit(event) {
    this.valueChange.emit(event);
  }
}
