import { CustomEnum, EnumResolverResult } from '@theia-cc/em/core';

const getSpecificEnumNameAndValues = (enums: CustomEnum[], name: string): EnumResolverResult[] => {
  if (!enums || !name) {
    return [];
  }

  const transformedMatchingEnums = enums
    .filter(({ enumName }) => enumName?.name === name)
    .map(({ enumName, selectedValue }) => ({
      enumName: enumName.name,
      selectedValue,
    }));

  return transformedMatchingEnums;
};

export default getSpecificEnumNameAndValues;
