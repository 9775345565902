import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { SharedUtilUiModule } from '@theia-cc/shared/util-ui';
import { UiSwitchModule } from 'ngx-toggle-switch';
import { ChoiceButtonPersonComponent } from './choice-button-person/choice-button-person.component';
import { ChoiceButtonSwitchComponent } from './choice-button-switch/choice-button-switch.component';
import { ChoiceButtonComponent } from './choice-button/choice-button.component';
import { StepColumnsComponent } from './step-columns/step-columns.component';
import { StepWrapperComponent } from './step-wrapper/step-wrapper.component';

@NgModule({
  declarations: [
    ChoiceButtonComponent,
    ChoiceButtonPersonComponent,
    ChoiceButtonSwitchComponent,
    StepColumnsComponent,
    StepWrapperComponent,
  ],
  imports: [CommonModule, SharedUtilUiModule, UiSwitchModule, TranslateModule],
  exports: [
    ChoiceButtonComponent,
    ChoiceButtonPersonComponent,
    ChoiceButtonSwitchComponent,
    StepColumnsComponent,
    StepWrapperComponent,
    TranslateModule,
    UiSwitchModule,
    SharedUtilUiModule,
  ],
})
export class SharedStepsUiModule {}
