import { Component, EventEmitter, Input, Output, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'app-offer-preview-switch',
  template: `
    <div
      [ngClass]="{
        'offer-preview-switch': true,
        'offer-preview-switch--active': active
      }">
      <div class="offer-preview-switch__icon" (click)="toggle()">
        <app-icon class="offer-preview-switch__icon-icon" [name]="icon" [size]="72"></app-icon>
      </div>
      <div class="offer-preview-switch__caption" (click)="toggle()">
        <div [innerHTML]="caption"></div>
        <div
          *ngIf="tooltipContent"
          class="offer-preview-switch__tooltip"
          [tooltip]="tooltipContent"
          placement="top"
          container="body">
          <img src="assets/icons/var/icn-info.svg" />
        </div>
      </div>
      <div class="offer-preview-switch__edit">
        <ng-content select="[editLink]"></ng-content>
      </div>
      <div class="offer-preview-switch__arrow">
        <ui-switch
          size="medium"
          color="#f5a623"
          [(ngModel)]="active"
          (change)="emit($event)"></ui-switch>
      </div>
    </div>
  `,
  encapsulation: ViewEncapsulation.None,
})
export class OfferPreviewSwitchComponent {
  @Input() caption: string;
  @Input() tooltipContent: string;
  @Input() icon: string;
  @Input() active = false;
  @Input() showTankDismantleModal = false;
  @Output() valueChange = new EventEmitter<boolean>();

  toggle() {
    this.active = !this.active;
    this.valueChange.emit(this.active);
  }

  emit(event) {
    this.valueChange.emit(event);
  }
}
