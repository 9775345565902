import { Component, EventEmitter, Input, Output, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'app-choice-button-switch',
  templateUrl: './choice-button-switch.component.html',
  encapsulation: ViewEncapsulation.None,
})
export class ChoiceButtonSwitchComponent {
  @Input() caption: string;
  @Input() icon: string;
  @Input() active = false;
  @Output() valueChange = new EventEmitter<boolean>();

  constructor() {}

  toggle() {
    this.active = !this.active;
    this.valueChange.emit(this.active);
  }
}
