import { NgRedux } from '@angular-redux/store';
import { DOCUMENT } from '@angular/common';
import { Inject, Injectable } from '@angular/core';
import {
  caseAgnosticQueryParam,
  cleanEmptyProperties,
  getRestQueryParams,
} from '@theia-cc/shared/helpers';
import {
  IConfigQueryParamsBase,
  IUserAddressParams,
  IUserInfoParams,
} from '@theia-cc/shared/models';
import { ShareLinkService } from '@theia-cc/shared/services';
import { IAppStatePorsche, ICollectedDataPorsche } from '../store/state';

export type ShareLinkQueryParams = Partial<ICollectedDataPorsche> &
  Partial<IUserInfoParams> &
  Partial<IUserAddressParams> & {
    Language?: string;
  };

@Injectable()
export class ShareLinkServicePorsche extends ShareLinkService {
  constructor(
    private store: NgRedux<IAppStatePorsche>,
    @Inject(DOCUMENT) private readonly document: Document
  ) {
    super();
  }

  urlLinkQueryParamsSelector(state: IAppStatePorsche): any {
    const { currentLanguage: Language } = state;

    const { restQueryParams } = this.splitQueryParams(state.config.queryParams);

    const data = {
      Language,
      ...restQueryParams,
    };

    return cleanEmptyProperties(data);
  }

  shareLinkQueryParamsSelector(state: IAppStatePorsche): ShareLinkQueryParams {
    const {
      collectedData: {
        chargingPower,
        connectpv,
        distanceFuseboxToChargingstation,
        fuseBox,
        orderType,
        parkingspace,
        poweroutlet,
        totalNumberOfParkingspaces,
        selectedTemplateIdEm,
        userAddress: { lat, lon, place: City, street: Street, zip: ZipCode },
        user: {
          email: Email,
          firstName: FirstName,
          lastName: LastName,
          telephone: PhoneNumber,
          title: Title,
          companyName: CompanyName,
        },
      },
      currentLanguage: Language,
    } = state;

    const { restQueryParams } = this.splitQueryParams(state.config.queryParams);

    const data = {
      chargingPower,
      connectpv,
      distanceFuseboxToChargingstation,
      fuseBox,
      orderType,
      parkingspace,
      poweroutlet,
      totalNumberOfParkingspaces,
      Language,
      selectedTemplateIdEm,
      lat,
      lon,
      City,
      Street,
      ZipCode,
      Email,
      FirstName,
      LastName,
      PhoneNumber,
      Title,
      CompanyName,

      ...restQueryParams,
    };

    return cleanEmptyProperties(data);
  }

  splitQueryParams<
    T = {
      collectedData: Partial<ICollectedDataPorsche>;
      Language: string;
      restQueryParams: Partial<ShareLinkQueryParams>;
    }
  >(queryParams: IConfigQueryParamsBase): T {
    const getCaseAgnosticQueryParam = caseAgnosticQueryParam(queryParams as any);

    const collectedData: Partial<ICollectedDataPorsche> = cleanEmptyProperties({
      connectpv: getCaseAgnosticQueryParam('connectpv'),
      distanceFuseboxToChargingstation: getCaseAgnosticQueryParam(
        'distanceFuseboxToChargingstation'
      ),
      fuseBox: getCaseAgnosticQueryParam('fuseBox'),
      manufacturerselection: getCaseAgnosticQueryParam('manufacturerselection'),
      numberOfParkingspacePreparedToBeElectrified: getCaseAgnosticQueryParam(
        'numberOfParkingspacePreparedToBeElectrified'
      ),
      numberOfParkingspaceToBeEquiped: getCaseAgnosticQueryParam('numberOfParkingspaceToBeEquiped'),
      orderType: getCaseAgnosticQueryParam('orderType'),
      parkingspace: getCaseAgnosticQueryParam('parkingspace'),
      poweroutlet: getCaseAgnosticQueryParam('poweroutlet'),
      totalNumberOfParkingspaces: getCaseAgnosticQueryParam('totalNumberOfParkingspaces'),
      selectedTemplateIdEm: getCaseAgnosticQueryParam('selectedTemplateIdEm'),
      chargingPower: getCaseAgnosticQueryParam('chargingPower'),

      userAddress: {
        lat: getCaseAgnosticQueryParam('lat'),
        lon: getCaseAgnosticQueryParam('lon'),
        place: getCaseAgnosticQueryParam('City'),
        street: getCaseAgnosticQueryParam('Street'),
        zip: getCaseAgnosticQueryParam('ZipCode'),
      },
      user: {
        email: getCaseAgnosticQueryParam('Email'),
        firstName: getCaseAgnosticQueryParam('FirstName'),
        lastName: getCaseAgnosticQueryParam('LastName'),
        telephone: getCaseAgnosticQueryParam('PhoneNumber'),
        title: getCaseAgnosticQueryParam('Title'),
        companyName: getCaseAgnosticQueryParam('CompanyName'),
      },
      dealerEmail: getCaseAgnosticQueryParam('dealeremail'),
    });

    return {
      Language: getCaseAgnosticQueryParam('Language'),
      collectedData,
      restQueryParams: getRestQueryParams(queryParams as any),
    } as unknown as T; // @TODO fix typing
  }
}
