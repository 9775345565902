import { NgRedux, select } from '@angular-redux/store';
import { DOCUMENT, Location } from '@angular/common';
import { Component, Inject, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import {
  EmobilityVariantSummaryViewModel,
  NboEmobilityCalculateRequest,
  NboEmobilityRequest,
} from '@api-cc';
import { marker as _ } from '@biesbjerg/ngx-translate-extract-marker';
import { TranslateService } from '@ngx-translate/core';
import {
  CHARGING_POWER_TYPE_ITEMS,
  FINISH_EM_CONTACT_COMMERCIAL,
  GO_TO_CONTACT_FORM,
  IChargingPowerTypeItem,
  IShareOfferPreviewResolveData,
  ITemplateEm,
  Icon,
  Image,
  defaultPartnerId,
  REQUEST_REMOTE_CHECK,
} from '@theia-cc/em/core';
import {
  EmStateAction,
  EmStateEffect,
  IAppStateEm,
  TranslationPropertyEm,
  genericTranslationSelector,
} from '@theia-cc/em/state';
import {
  caseAgnosticQueryParam,
  getLocalizedItemFromILanguageSpecificObject,
} from '@theia-cc/shared/helpers';
import { ILanguageSpecific, ILanguageSpecificStringWithType } from '@theia-cc/shared/models';
import { SanityImageService, SanityService, ShareLinkService } from '@theia-cc/shared/services';
import { SharedStoreEffect, WizardAction } from '@theia-cc/shared/store';
import { Observable, Subscription } from 'rxjs';
import { distinctUntilChanged, map } from 'rxjs/operators';
import { filterTemplates } from '../utils/productline-filtering.utils';

const translationProperty: TranslationPropertyEm = 'offerPreviewCustom';

@Component({
  selector: 'app-offer-preview-custom-em',
  templateUrl: './offer-preview-custom.component.html',
  encapsulation: ViewEncapsulation.None,
})
export class OfferPreviewCustomComponent implements OnInit, OnDestroy {
  @select((state: IAppStateEm) => state.collectedData.nboFetchedEm)
  readonly nbo$: Observable<any[]>;

  @select((store: IAppStateEm) => genericTranslationSelector(store, translationProperty, 'title'))
  title$: Observable<ILanguageSpecificStringWithType>;

  @select((store: IAppStateEm) =>
    genericTranslationSelector(store, translationProperty, 'subtitle')
  )
  subtitle$: Observable<ILanguageSpecificStringWithType>;

  @select((state: IAppStateEm) => state)
  private state$: Observable<IAppStateEm>;

  private urlLinkQueryParams$: Observable<any> =
    // @ts-ignore
    this.state$.pipe(map(state => this.shareLinkService.urlLinkQueryParamsSelector(state)));

  private nboEmpty: EmobilityVariantSummaryViewModel = <EmobilityVariantSummaryViewModel>{};
  public templateSelected = '';
  public templateSelectedObject: ITemplateEm;
  public templateListContent: ITemplateEm[] = [];
  public templateCount = 0;
  public chargingPowerTypeItems: IChargingPowerTypeItem[] = [];
  public isCommercial: boolean;

  private subscription: Subscription = new Subscription();

  constructor(
    private wizardAction: WizardAction,
    private effect: SharedStoreEffect,
    private emStateEffect: EmStateEffect,
    private stepActions: EmStateAction,
    private store: NgRedux<IAppStateEm>,
    private route: ActivatedRoute,
    private shareLinkService: ShareLinkService,
    private location: Location,
    private translate: TranslateService,
    private sanityService: SanityService,
    private sanityImageService: SanityImageService,
    @Inject(DOCUMENT) private readonly document: Document
  ) {
    this.wizardAction.showOfferPreviewLoadingSpinner();
  }

  ngOnInit(): void {
    this.effect.setNavbarDefaultEmpty();

    const appState: IAppStateEm = this.store.getState();

    this.chargingPowerTypeItems = CHARGING_POWER_TYPE_ITEMS;

    this.isCommercial =
      appState.collectedData.whereToInstallCharger ===
      NboEmobilityCalculateRequest.WhereToInstallChargerEnum.CommunitySolution;

    const partnerId = appState.config.queryParams.partnerId ?? defaultPartnerId;

    this.emStateEffect.getMatchingTemplateNames().then(templateNames => {
      this.sanityService.getAllEmTemplatesByPartnerId(partnerId).then(templates => {
        this.prepareTemplates(templateNames, templates);
        this.getNboVariantsForTemplates(this.templateListContent).then(() => {
          const offerPreviewResolveData: IShareOfferPreviewResolveData =
            this.route.snapshot.data.templateSelected || {};
          const { chargingPower } = offerPreviewResolveData;
          this.selectDefaultTemplate(appState.collectedData.selectedTemplateIdEm);
          this.applyChargingPowerFromQueryParamsForSelectedTemplate(chargingPower);
        });

        // Subscribe to the nbo data in the store
        this.nbo$.subscribe(nboList => {
          if (nboList && nboList.length > 0) {
            this.templateListContent = this.templateListContent.map(template => {
              const data = nboList.filter(item => item.templateId === template.id);
              return { ...template, data };
            });
          }
        });
      });
    });

    this.setActionsForFloatingFooterButtons();

    if (!this.route.snapshot.data.isDisableOfferSharing) {
      this.subscribeToSetActualQueryParams();
    }

    this.shareLinkService.setOfferReviewStepPathName(this.document.location.pathname);
  }

  public templateSelect(template: ITemplateEm): void {
    this.templateSelected = template.id;
    this.templateSelectedObject = this.templateListContent.find(
      templateFromList => templateFromList.id === template.id
    );
    this.stepActions.storeSelectedTemplate({
      ...template,
      valueFormatted: this.valueFormatted(template.id),
    });
    this.stepActions.storeChargingPower(template.chargingPower);
  }

  // Different selector functions to get the correct data
  public templateSelectImage(templateId: string): string {
    const icon: Icon = this.templateListContent.filter(item => item.id === templateId)[0].icon;
    const sanityImageUrl = this.sanityImageService.getImageUrlBuilder(icon.asset._ref).url();
    return sanityImageUrl;
  }

  public templateSelectTitle(templateId: string): string {
    const title = this.templateListContent.filter(item => item.id === templateId)[0].title;
    return getLocalizedItemFromILanguageSpecificObject(title, this.translate.currentLang);
  }

  public templateSelectDescription(templateId: string): string {
    const description = this.templateListContent.filter(item => item.id === templateId)[0]
      .description;
    return getLocalizedItemFromILanguageSpecificObject(description, this.translate.currentLang);
  }

  // Method to bring the data of a template in the right format
  public valueFormatted(templateId: string): any {
    const appState: IAppStateEm = this.store.getState();
    const nboData: EmobilityVariantSummaryViewModel = this.templateListContent.filter(
      item => item.id === templateId
    )[0].data[0] as any;

    const nboResult = { ...this.nboEmpty, ...nboData };

    return {
      ...this.emStateEffect.valueFormatted(
        nboResult,
        appState.collectedData.chargingPower,
        caseAgnosticQueryParam(appState.config.queryParams)('partnerId')
      ),
      productLineName: this.templateSelectTitle(templateId),
    };
  }

  public templateSelectImages(templateId: string): string[] {
    const images: Image[] = this.templateListContent.filter(item => item.id === templateId)[0]
      .images;

    const imageUrls: string[] = [];

    images.forEach((image: Image) => {
      const sanityImageUrl = this.sanityImageService.getImageUrlBuilder(image.asset._ref).url();
      imageUrls.push(sanityImageUrl);
    });

    return imageUrls;
  }

  public setChargingPower(event: NboEmobilityCalculateRequest.ChargingPowerEnum): void {
    this.stepActions.storeChargingPower(event);
    this.updateSelectedTemplateChargingPower(event);
    this.getNboVariantsForTemplates([this.templateSelectedObject]);
  }

  public getLocalizedItemFromObjectWrapper(object: ILanguageSpecific<string>): string {
    return getLocalizedItemFromILanguageSpecificObject(object, this.translate.currentLang);
  }

  public getIcon(template: ITemplateEm): string {
    return this.sanityImageService.getImageUrlBuilder(template.icon.asset._ref).url();
  }

  private setActionsForFloatingFooterButtons(): void {
    const { navbarPrimaryButtonProperties, navbarSecondaryButtonProperties, showShareButton } =
      this.route.snapshot.data;

    const action = this.isCommercial ? FINISH_EM_CONTACT_COMMERCIAL : REQUEST_REMOTE_CHECK;
    const actionButtonTranslations = genericTranslationSelector(
      this.store.getState(),
      'offerPreview',
      this.isCommercial
        ? 'commercialBottomBoxGoToContactButton'
        : 'residentialBottomBoxRequestRemoteCheckButton'
    )?.translations;
    const name = actionButtonTranslations
      ? getLocalizedItemFromILanguageSpecificObject(
          actionButtonTranslations,
          this.translate.currentLang
        )
      : 'EM.OFFERPREVIEW.GO_TO_CONTACT_FORM';

    this.wizardAction.setNavbarSecondaryButtonProperties(
      navbarSecondaryButtonProperties || {
        hidden: false,
        name,
        action,
        disabled: false,
      }
    );

    if (navbarPrimaryButtonProperties) {
      this.wizardAction.setNavbarPrimaryButtonProperties(navbarPrimaryButtonProperties);
    }

    if (showShareButton !== undefined) {
      this.wizardAction.setShareButtonVisibility(showShareButton);
    }
  }

  private subscribeToSetActualQueryParams(): void {
    this.subscription.add(
      this.urlLinkQueryParams$
        .pipe(
          map(queryParams =>
            this.shareLinkService.generateQueryParamsLink(
              queryParams,
              this.document.location.pathname
            )
          ),
          distinctUntilChanged()
        )
        .subscribe(queryParamsLink => this.location.replaceState(queryParamsLink))
    );
  }

  private selectDefaultTemplate(templateId: string): void {
    const templateFromQueryParamsOrTheFirstFromListContent =
      (templateId && this.templateListContent.find(template => template.id === templateId)) ||
      this.templateListContent[0];
    this.templateSelect(templateFromQueryParamsOrTheFirstFromListContent);
  }

  private applyChargingPowerFromQueryParamsForSelectedTemplate(
    chargingPower: NboEmobilityRequest.ChargingPowerEnum
  ): void {
    const isHasDifferentChargingPowerValueFromQueryParams =
      chargingPower && chargingPower !== this.templateSelectedObject.chargingPower;
    const isChargingPowerCorrectForSelectedTemplate =
      this.templateSelectedObject.supportsMultipleChargingPowers &&
      this.chargingPowerTypeItems.some(({ id }) => chargingPower === id);

    if (
      isHasDifferentChargingPowerValueFromQueryParams &&
      isChargingPowerCorrectForSelectedTemplate
    ) {
      this.setChargingPower(chargingPower);
    }
  }

  private prepareTemplates(availableProductlineNames: string[], templates: ITemplateEm[]): void {
    this.templateListContent = filterTemplates(
      availableProductlineNames,
      templates,
      this.store.getState().collectedData
    );
    this.templateCount = this.templateListContent.length;
  }

  private getNboVariantsForTemplates(templates: ITemplateEm[]): Promise<any> {
    const appState = this.store.getState();

    const nboFetched = appState.collectedData.nboFetchedEm?.length
      ? Promise.resolve(appState.collectedData.nboFetchedEm)
      : this.emStateEffect.getNboVariants(templates);

    return nboFetched.then(() => {
      setTimeout(() => this.wizardAction.hideOfferPreviewLoadingSpinner(), 240);
    });
  }

  private updateSelectedTemplateChargingPower(
    chargingPower: NboEmobilityCalculateRequest.ChargingPowerEnum
  ): void {
    this.templateSelectedObject = {
      ...this.templateSelectedObject,
      chargingPower,
    };
    this.templateListContent = this.templateListContent.map(template =>
      template.id === this.templateSelectedObject.id ? this.templateSelectedObject : template
    );
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }
}
