<button
  type="button"
  [ngClass]="{
    'btn btn-outline-primary': true,
    'choice-button': true,
    'choice-button--active': active
  }">
  <div
    class="choice-button__icon"
    *ngIf="icon !== false"
    [style.width.rem]="width"
    [style.height.rem]="height">
    <app-icon
      *ngIf="!isImageFile()"
      class="choice-button__icon-icon"
      [name]="icon"
      [size]="72"></app-icon>
    <div
      *ngIf="isImageFile()"
      class="choice-button__icon-image"
      [ngStyle]="icon && { 'background-image': 'url(' + icon + ')' }">
      <ng-container *ngIf="icon === null">?</ng-container>
    </div>
  </div>
  <div *ngIf="caption" class="choice-button__caption">{{ caption }}</div>
  <div *ngIf="captionHtml" class="choice-button__caption" [innerHTML]="captionHtml"></div>
  <div class="choice-button__arrow">
    <app-icon
      class="choice-button__arrow-icon"
      [name]="choiceButtonChevronRightIconName"
      [size]="32"></app-icon>
  </div>
</button>
