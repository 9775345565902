import { NgRedux } from '@angular-redux/store';
import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve } from '@angular/router';
import { EmStateAction, EmStateEffect, IAppStateEm } from '@theia-cc/em/state';
import { filterTemplatesWithoutCms } from '@theia-cc/em/steps/offer-preview';
import { cleanEmptyProperties } from '@theia-cc/shared/helpers';
import {
  ISplitQueryParamsResultBase,
  LanguageService,
  ShareLinkService,
} from '@theia-cc/shared/services';
import { PORSCHE_TEMPLATES } from '../config/porsche-templates';
import { ICollectedDataPorsche } from '../store/state';

@Injectable()
export class ShareOfferPreviewResolver implements Resolve<Promise<boolean>> {
  constructor(
    private stepActions: EmStateAction,
    private store: NgRedux<IAppStateEm>,
    public shareLinkService: ShareLinkService,
    private languageService: LanguageService,
    private emStateEffect: EmStateEffect
  ) {}

  async resolve(route: ActivatedRouteSnapshot): Promise<boolean> {
    const { collectedData, Language } = this.shareLinkService.splitQueryParams<
      Omit<ISplitQueryParamsResultBase<ICollectedDataPorsche>, 'partnerId'>
    >(route.queryParams);

    if (Language) {
      this.languageService.setCurrentLanguage(Language);
    }

    if (
      this.store.getState().wizard.isSharedOfferPreview &&
      Object.keys(cleanEmptyProperties(collectedData)).length
    ) {
      this.stepActions.setConfigSettings(cleanEmptyProperties(collectedData));
    }

    return this.emStateEffect
      .getMatchingTemplateNames()
      .then(templateNames => {
        const templates = filterTemplatesWithoutCms(
          templateNames,
          PORSCHE_TEMPLATES,
          this.store.getState().collectedData
        );

        if (templates.length) {
          const template = templates[0];
          this.stepActions.storeSelectedTemplate(template);
          this.stepActions.storeChargingPower(template.chargingPower);
          return true;
        }

        return false;
      })
      .catch(() => false);
  }
}
