import { NboEmobilityCalculateRequest, NboEmobilityRequest } from '@api-cc';
import { marker as _ } from '@biesbjerg/ngx-translate-extract-marker';
import {
  ITemplateEmWithoutCms,
  PORSCHE_COMMERCIAL_INSTALLATION_ONLY,
  PORSCHE_RESIDENTIAL_INSTALLATION_ONLY,
} from '@theia-cc/em/core';

export const PORSCHE_TEMPLATES: ITemplateEmWithoutCms[] = [
  {
    id: PORSCHE_RESIDENTIAL_INSTALLATION_ONLY,
    whereToInstallCharger:
      NboEmobilityCalculateRequest.WhereToInstallChargerEnum.IndividualSolution,
    tab: _('EM.OFFERPREVIEW.TEMPLATES.NBO_TEMPLATE_KEBA_WALLBOX_KECONTACT_P30_TAB'),
    title: _('EM.OFFERPREVIEW.TEMPLATES.NBO_TEMPLATE_KEBA_WALLBOX_KECONTACT_P30_TITLE'),
    icon: 'assets/img/offerPreview/charging-station-renault-keba.jpg',
    description: _('EM.OFFERPREVIEW.TEMPLATES.NBO_TEMPLATE_KEBA_WALLBOX_KECONTACT_P30_DESCRIPTION'),
    recommended: false,
    data: {
      supportedOrderTypes: [
        NboEmobilityRequest.OrderTypeEnum.AccordingToCost,
        NboEmobilityRequest.OrderTypeEnum.FlatRate,
      ],
    },
    images: ['assets/img/offerPreview/charging-station-renault-keba_modal.jpg'],
    chargingPower: NboEmobilityRequest.ChargingPowerEnum.Eleven,
    supportsMultipleChargingPowers: true,
    withConnectpv: NboEmobilityCalculateRequest.SmartEnergyConnectionEnum.Desired,
  },
  {
    id: PORSCHE_COMMERCIAL_INSTALLATION_ONLY,
    whereToInstallCharger: NboEmobilityCalculateRequest.WhereToInstallChargerEnum.CommunitySolution,
    tab: _('EM.OFFERPREVIEW.TEMPLATES.NBO_TEMPLATE_KEBA_WALLBOX_KECONTACT_P30_TAB'),
    title: _('EM.OFFERPREVIEW.TEMPLATES.NBO_TEMPLATE_KEBA_WALLBOX_KECONTACT_P30_TITLE'),
    icon: 'assets/img/offerPreview/charging-station-renault-keba.jpg',
    description: _('EM.OFFERPREVIEW.TEMPLATES.NBO_TEMPLATE_KEBA_WALLBOX_KECONTACT_P30_DESCRIPTION'),
    recommended: false,
    data: {
      supportedOrderTypes: [
        NboEmobilityRequest.OrderTypeEnum.AccordingToCost,
        NboEmobilityRequest.OrderTypeEnum.FlatRate,
      ],
    },
    images: ['assets/img/offerPreview/charging-station-renault-keba_modal.jpg'],
    chargingPower: NboEmobilityRequest.ChargingPowerEnum.Eleven,
    supportsMultipleChargingPowers: true,
    withConnectpv: NboEmobilityCalculateRequest.SmartEnergyConnectionEnum.Desired,
  },
];
